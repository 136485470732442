import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Alvearie/alvearie.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Getting involved with Alvearie`}</h2>
    <p>{`Alvearie is open source and we encourage all to use, provide feedback, and contribute to the projects. The first step is to engage with the community. This can be done by:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Ask questions in our GitHub discussion boards`}</strong></li>
    </ul>
    <p>{`Each of our GitHub repositories (`}<a parentName="p" {...{
        "href": "https://github.com/alvearie"
      }}>{`github.com/alvearie`}</a>{`) has a GitHub Discussions board enabled where anyone can engage in a discussion, ask a question, or view discussion history.`}</p>
    <p />
    <h2>{`Code of Conduct`}</h2>
    <ul>
      <li parentName="ul">{`Alvearie follows the code of conduct that is defined by the `}<a parentName="li" {...{
          "href": "http://contributor-covenant.org"
        }}>{`Contributor Convenant`}</a></li>
    </ul>
    <p />
    <h2>{`License`}</h2>
    <ul>
      <li parentName="ul">{`Alvearie components are all licensed under the Apache 2.0 license.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      